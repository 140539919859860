import { Injectable, signal } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BehaviorSubject, Subject } from 'rxjs';

import {
  AgGridColumnTypeEnum,
  ClrTabsEnum,
  FscErrorMessages,
  GeneralRefDataKeyEnum,
  LCMSAccessTypes,
  LaneRateErrorMessages,
  MessagesEnum,
  MileRangeErrorMessages,
  MotEnum,
  PublishStatusEnum,
  ReferenceDataKeyEnum,
  ScreenType,
  ValidationType,
  VersionTypeEnum,
} from '../enums';
import { Carrier } from 'src/app/lcms/shared/models/truck/carrier.model';
import {
  CarrierMapping,
  ProductMapping,
  TerminalMapping,
} from 'src/app/lcms/shared/models/truck/carrier-terminal-mapping.model';
import { LaneRate } from 'src/app/lcms/shared/models/truck/lane-rate.model';
import { ReferenceData } from 'src/app/lcms/shared/models/reference-data';
import { TerminalProductMapping } from 'src/app/lcms/shared/models/truck/terminal-product-mapping.model';
import { IMileRange } from 'src/app/lcms/shared/models/interfaces';
import { Validations } from 'src/app/lcms/shared/models/truck/validations.model';
import { ClrTerminal } from 'src/app/lcms/shared/models/truck/clr-terminal-model';
import { MileageZone } from 'src/app/lcms/shared/models/truck/mileage-zone.model';
import { SspTerminal } from 'src/app/lcms/shared/models/truck/ssp-terminal.model';
import { VersionMetadata } from 'src/app/lcms/shared/models/truck/version-metadata.model';
import { SnackBarService } from 'src/app/lcms/shared/services/snack-bar.service';
import { LcmsService } from 'src/app/lcms/shared/services/lcms.service';
import { AcBillingCode, AccessorialCharge } from 'src/app/lcms/shared/models/truck/accessorial-charge.model';
import { ColDef } from 'ag-grid-community';
import { FscRegionIndexRate, FscRates } from '../shared/models/truck/fsc-rates.model';

@Injectable({
  providedIn: 'root',
})
export class ClrService {
  private _selectedMot: string = MotEnum.TRUCK;
  private _selectedCarrierId!: string;
  private _selectedVersionId!: string;
  private _selectedCarrier!: Carrier;
  private _selectedVersion!: VersionMetadata | undefined;
  public selectedMot$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this._selectedMot
  );
  public selectedCarrierId$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public carrierVersionChanged$: Subject<any> = new Subject<any>();
  public carrierVersionIdChanged$: Subject<any> = new Subject<any>();
  public metaDataUpdated$: Subject<string> = new Subject<string>();
  public confirmDialogSubject$: Subject<any> = new Subject<any>();
  public showErrorRecordsOnly$: Subject<any> = new Subject<any>();
  public errorPanelClosed$: Subject<any> = new Subject<any>();

  public allCarrierMappingData: CarrierMapping[] = [];
  public allTerminalMappingData: TerminalProductMapping[] = [];
  public clrReferenceData!: ReferenceData;
  public clrRefDataSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isClrRefDataFetched: boolean = false;
  public refreshRefMappingDataTrigger$: Subject<void> = new Subject<void>();
  public filterExpanded = signal(true);
  public userName = signal('');
  public userAADID = signal('');
  public laneRateMetadata: VersionMetadata[] = [];
  public clrHomeDestroyed$ = new Subject<boolean>();
  constructor(
    private snackBarService: SnackBarService,
    private lcmsService: LcmsService,
  ) { 
  }

  set selectedMot(value: any) {
    this._selectedMot = value;
    this.selectedMot$.next(this._selectedMot);
  }

  get selectedMot(): string {
    return this._selectedMot;
  }

  set selectedCarrierId(value: any) {
    this._selectedCarrierId = value;
    this._selectedCarrier = this.getCarrierById(this._selectedCarrierId);
    this.selectedVersionId = undefined;
    this.selectedCarrierId$.next(this._selectedCarrierId);
  }

  get selectedCarrierId(): string {
    return this._selectedCarrierId;
  }

  set selectedVersionId(value: any) {
    this._selectedVersionId = value;
    this._selectedVersion = this.laneRateMetadata.find(
      (version: VersionMetadata) => version.Id === value
    );
    this.carrierVersionIdChanged$.next(this._selectedVersionId);
  }

  get selectedVersionId(): string {
    return this._selectedVersionId;
  }

  get selectedCarrier(): Carrier {
    return this._selectedCarrier;
  }

  get selectedVersion(): VersionMetadata | undefined {
    return this._selectedVersion;
  }

  public getCarrierById(carrierId: string): any {
    return this.clrReferenceData
      .getReferenceDataByKey(ReferenceDataKeyEnum.CARRIERS)
      .find((c: any) => c.CarrierId === carrierId);
  }

  public getCarrierAliasById(carrierId: string) {
    return this.getCarrierById(carrierId)?.Alias;
  }

  public getTerminalById(terminalNumber: string): any {
    return this.clrReferenceData
      .getReferenceDataByKey(ReferenceDataKeyEnum.TERMINALS)
      .find((c: any) => c.TerminalNumber === terminalNumber);
  }

  public getProductById(productId: string): any {
    return this.clrReferenceData
      .getReferenceDataByKey(ReferenceDataKeyEnum.PRODUCT)
      .find((c: any) => c.ProductId === productId);
  }

  public getTerminalAliasById(terminalNumber: string) {
    return this.getTerminalById(terminalNumber)?.Alias;
  }

  public getCarrierMappingById(
    carrierId: string | undefined
  ): CarrierMapping | undefined {
    return this.allCarrierMappingData.find(
      (c: CarrierMapping) => c.CarrierId === carrierId
    );
  }

  public getTerminalToProductMappingById(
    terminalNumber: string | undefined
  ): TerminalProductMapping | undefined {
    return this.allTerminalMappingData.find(
      (c: TerminalProductMapping) => c.TerminalNumber === terminalNumber
    );
  }

  public getMappedTerminalForCarrierId(
    carrierId: string | undefined,
    terminalNumber: any
  ): TerminalMapping | undefined {
    const carrierMapping = this.allCarrierMappingData.find(
      (c: CarrierMapping) => c.CarrierId === carrierId
    );
    return carrierMapping?.Terminals.find(
      (t: TerminalMapping) => t.TerminalNumber === terminalNumber
    );
  }

  public calculateBaseRateWithStopOff(
    baseRate: number,
    stopOffValue: number = 0
  ): number {
    const calculatedStopOff = stopOffValue / 2 + baseRate;
    return parseFloat(calculatedStopOff.toFixed(2));
  }

  public getMileRangeString(mileRange: any) {
    if (mileRange.MileRange) {
      return (
        `${mileRange.MileRange.StartRange || ''}` +
        ' - ' +
        `${mileRange.MileRange.EndRange || ''}`
      );
    } else {
      return (
        `${mileRange.StartRange || ''}` + ' - ' + `${mileRange.EndRange || ''}`
      );
    }
  }

  public validateCLRData(laneRateData: LaneRate, mileRanges: any) {
    let allValidations: Validations[] = [];

    let errorValidationsForFullLoad: Validations = new Validations({
      ValidationType: ValidationType.Error,
      ScreenType: ScreenType.FullLoad,
    });

    let errorValidationsForSplitLoad: Validations = new Validations({
      ValidationType: ValidationType.Error,
      ScreenType: ScreenType.SplitLoad,
    });

    let errorValidationsForMMP: Validations = new Validations({
      ValidationType: ValidationType.Error,
      ScreenType: ScreenType.MileageMetricPricing,
    });

    let errorValidationsForSSP: Validations = new Validations({
      ValidationType: ValidationType.Error,
      ScreenType: ScreenType.SpecificServicePricing,
    });

    let errorValidationsForAC: Validations = new Validations({
      ValidationType: ValidationType.Error,
      ScreenType: ScreenType.AccessorialCharge,
    });

    //Full Load
    if (mileRanges?.fullLoad) {
      this.validateMileRanges(
        mileRanges?.fullLoad,
        errorValidationsForFullLoad
      );
    }
    if (laneRateData?.FullLoad && laneRateData?.FullLoad.IsUIModified) {
      this.validateLaneRateDataErrors(
        ClrTabsEnum.FULL_LOAD,
        laneRateData.FullLoad,
        errorValidationsForFullLoad
      );
    }
    if (
      errorValidationsForFullLoad.ErrorMessages.length > 0 ||
      errorValidationsForFullLoad.WarningMessages.length > 0
    ) {
      allValidations.push(errorValidationsForFullLoad);
    }

    //Split Load
    if (mileRanges?.splitload) {
      this.validateMileRanges(
        mileRanges?.splitload,
        errorValidationsForSplitLoad
      );
    }
    if (laneRateData?.SplitLoad && laneRateData?.SplitLoad.IsUIModified) {
      this.validateLaneRateDataErrors(
        ClrTabsEnum.SPLIT_LOAD,
        laneRateData.SplitLoad,
        errorValidationsForSplitLoad
      );
      this.validateLaneRateDataErrors(
        ClrTabsEnum.SPLIT_LOAD,
        laneRateData.SplitLoad,
        errorValidationsForSplitLoad,
        true
      );
    }
    if (
      errorValidationsForSplitLoad.ErrorMessages.length > 0 ||
      errorValidationsForSplitLoad.WarningMessages.length > 0
    ) {
      allValidations.push(errorValidationsForSplitLoad);
    }

    //Mileage Metric Pricing
    if (mileRanges?.mmp) {
      this.validateMileRanges(mileRanges?.mmp, errorValidationsForMMP);
    }
    if (
      laneRateData?.MileageMetricPricing &&
      laneRateData?.MileageMetricPricing.IsUIModified
    ) {
      this.validateLaneRateDataErrors(
        ClrTabsEnum.MILEAGE_METRIC_PRICING,
        laneRateData.MileageMetricPricing,
        errorValidationsForMMP
      );
    }
    if (
      errorValidationsForMMP.ErrorMessages.length > 0 ||
      errorValidationsForMMP.WarningMessages.length > 0
    ) {
      allValidations.push(errorValidationsForMMP);
    }

    //Specific Service Pricing
    if (
      laneRateData?.SpecificServicePricing &&
      laneRateData?.SpecificServicePricing.IsUIModified
    ) {
      this.validateSSPData(
        laneRateData.SpecificServicePricing,
        errorValidationsForSSP
      );
    }
    if (
      errorValidationsForSSP.ErrorMessages.length > 0 ||
      errorValidationsForSSP.WarningMessages.length > 0
    ) {
      allValidations.push(errorValidationsForSSP);
    }


    // Accessorial Charges
    if (
      laneRateData?.AccessorialCharge &&
      laneRateData?.AccessorialCharge.IsUIModified
    ) {
      this.validateACData(
        laneRateData.AccessorialCharge,
        errorValidationsForAC
      );
    }
    if (
      errorValidationsForAC.ErrorMessages.length > 0 ||
      errorValidationsForAC.WarningMessages.length > 0
    ) {
      allValidations.push(errorValidationsForAC);
    }

    return allValidations;
  }

  public validateMileRanges(
    mileRange: any[],
    mileRangeValidations: Validations
  ) {
    mileRange.forEach((v) => (v.InValid = false));
    let messages: string[] = [];
    let duplicateRanges: any[] = [];
    //No Mile ranges found.
    if (mileRange.length === 0) {
      messages.push(MileRangeErrorMessages.NO_RANGE);
    } else {
      let firstMileRange = mileRange[0];
      //First range is starting with non-zero value
      if (
        firstMileRange?.MileRange?.StartRange != undefined &&
        firstMileRange?.MileRange?.StartRange !== 0
      ) {
        firstMileRange.InValid = true;
        messages.push(
          MileRangeErrorMessages.INITIAL_RANGE_0.replace(
            '<Range>',
            this.getMileRangeString(firstMileRange)
          )
        );
      }
      for (let i = 0; i < mileRange?.length - 1; i++) {
        let currentRange = mileRange[i];
        let nextRange = mileRange[i + 1];
        //Both start range and end range are undefined.
        if (
          currentRange.MileRange.StartRange == undefined ||
          currentRange.MileRange.EndRange == undefined
        ) {
          mileRange[i - 1].InValid = true;
          messages.push(
            MileRangeErrorMessages.EMPTY_RANGE.replace(
              '<Range>',
              this.getMileRangeString(mileRange[i - 1])
            )
          );
        } else {
          //End Range is less than start range
          if (
            currentRange.MileRange.EndRange < currentRange.MileRange.StartRange
          ) {
            currentRange.InValid = true;
            messages.push(
              MileRangeErrorMessages.INVALID_RANGE.replace(
                '<Range>',
                this.getMileRangeString(currentRange)
              )
            );
          }

          this.validateMileRangeDuplicacy(
            mileRange,
            duplicateRanges,
            currentRange,
            messages
          );
          this.validateMileRangeContinuity(currentRange, nextRange, messages);
        }
      }
    }
    mileRangeValidations.ErrorMessages =
      mileRangeValidations.ErrorMessages.concat(messages);
  }

  public validateMileRangeDuplicacy(
    mileRange: any[],
    duplicateRanges: any[],
    currentRange: any,
    messages: string[]
  ) {
    let matchedMileRanges = mileRange.filter(
      (m) =>
        m.MileRange.StartRange === currentRange.MileRange.StartRange &&
        m.MileRange.EndRange === currentRange.MileRange.EndRange
    );

    //Duplicate range found anywhere in data
    if (
      matchedMileRanges?.length > 1 &&
      !duplicateRanges.find((r) => r === this.getMileRangeString(currentRange))
    ) {
      duplicateRanges.push(this.getMileRangeString(currentRange));
      currentRange.InValid = true;
      messages.push(
        MileRangeErrorMessages.DUPLICATE_RANGE.replace(
          '<Range>',
          this.getMileRangeString(currentRange)
        )
      );
    }
  }

  public validateMileRangeContinuity(
    currentRange: any,
    nextRange: any,
    messages: string[]
  ) {
    if (
      currentRange.MileRange.EndRange !==
      nextRange.MileRange.StartRange - 1
    ) {
      //Overlapping ranges
      if (
        currentRange.MileRange.EndRange >
        nextRange.MileRange.StartRange - 1
      ) {
        currentRange.InValid = true;
        nextRange.InValid = true;
        messages.push(
          MileRangeErrorMessages.OVERLAPPING_RANGE.replace(
            '<Range1>',
            this.getMileRangeString(currentRange)
          ).replace('<Range2>', this.getMileRangeString(nextRange))
        );
      }

      //Discontinuous ranges
      else if (
        currentRange.MileRange.EndRange <
        nextRange.MileRange.StartRange - 1
      ) {
        currentRange.InValid = true;
        nextRange.InValid = true;
        messages.push(
          MileRangeErrorMessages.DISCONTINUOUS_RANGE.replace(
            '<Range1>',
            this.getMileRangeString(currentRange)
          ).replace('<Range2>', this.getMileRangeString(nextRange))
        );
      }
    }
  }

  /**
   *
   * @param terminalNumber terminalNumber of a terminal
   * @returns boolean
   *
   * Given a terminalNumber from the currently loaded carrier, this method returns true if it is visible in the grid
   * as determined by the IsVisibleOnFulLoad or IsVisibleOnSplitLoad properties of the terminal
   */
  public isTerminalVisibleOnGrid(
    terminalNumber: string,
    tabName: ClrTabsEnum,
    clrData: ClrTerminal[] | undefined,
    products?: ProductMapping[] | undefined
  ): boolean {
    let isTerminalActive =
      this.getTerminalById(terminalNumber)?.sys_isDeleted === false;
    //If terminal in active return terminal not visible
    if (
      !isTerminalActive &&
      this.selectedVersion?.VersionType === VersionTypeEnum.Live
    ) {
      return false;
    }
    //If terminal active, no further check required for SSP tab.
    if (tabName === ClrTabsEnum.SPECIFIC_SERVICE_PRICING) {
      return true;
    }

    //Perform is visible flag check for other tabs.
    let isVisibleOnGrid = true;
    if (tabName === ClrTabsEnum.FULL_LOAD) {
      if (this.selectedVersion?.VersionType === VersionTypeEnum.Live) {
        isVisibleOnGrid = this.getMappedTerminalForCarrierId(
          this.selectedCarrier.CarrierId,
          terminalNumber
        )?.IsVisibleOnFullLoad!;
      } else {
        isVisibleOnGrid =
          clrData?.some(
            (t: ClrTerminal) => t.TerminalNumber === terminalNumber
          ) || false;
      }
    } else if (tabName === ClrTabsEnum.SPLIT_LOAD) {
      if (this.selectedVersion?.VersionType === VersionTypeEnum.Live) {
        isVisibleOnGrid = this.getMappedTerminalForCarrierId(
          this.selectedCarrier.CarrierId,
          terminalNumber
        )?.IsVisibleOnSplitLoad!;
      } else {
        isVisibleOnGrid =
          clrData?.some(
            (t: ClrTerminal) => t.TerminalNumber === terminalNumber
          ) || false;
      }
    } else if (tabName === ClrTabsEnum.MILEAGE_METRIC_PRICING) {
      if (this.selectedVersion?.VersionType === VersionTypeEnum.Live) {
        isVisibleOnGrid =
          products! &&
          products.length > 0 &&
          this.getMappedTerminalForCarrierId(
            this.selectedCarrier.CarrierId,
            terminalNumber
          )?.IsVisibleOnMetricPricing!;
      } else {
        isVisibleOnGrid =
          clrData?.some(
            (t: ClrTerminal) => t.TerminalNumber === terminalNumber
          ) || false;
      }
    }

    return isVisibleOnGrid;
  }

  public isProductVisibleOnGrid(p: ProductMapping, tabName: ClrTabsEnum, clrData: ClrTerminal[] | undefined,) {
    let isVisibleOnGrid: boolean = true;

    if (tabName === ClrTabsEnum.MILEAGE_METRIC_PRICING) {
      if (this.selectedVersion?.VersionType === VersionTypeEnum.Live) {
        isVisibleOnGrid = p.IsVisibleOnMetricPricing;
      } else {
        isVisibleOnGrid = clrData?.some(
          (t: ClrTerminal) => t.ProductId === p.ProductId
        ) || false;;
      }
    }

    return isVisibleOnGrid;
  }

  public validateLaneRateDataErrors(
    tabName: ClrTabsEnum,
    dataToValidate: any,
    dataValidations: Validations,
    checkStopOff: boolean = false
  ) {
    let errorMessages: string[] = [];
    let warningMessages: string[] = [];
    let prefix = checkStopOff ? 'Base Rate w/stop off: ' : 'Base Rate: ';

    if (dataToValidate.Terminals?.length > 0) {
      dataToValidate.Terminals.forEach((t: ClrTerminal) => {
        if (!checkStopOff) {
          t.MileageZones.forEach((v) => (v.InValid = false));
        }
        let terminalName = this.clrReferenceData.getReferenceDataByKeyAndId(
          ReferenceDataKeyEnum.TERMINALS,
          t.TerminalNumber
        )?.Alias;

        //If terminal not visible on UI, Skip validations.
        if (
          !this.isTerminalVisibleOnGrid(
            t.TerminalNumber,
            tabName,
            dataToValidate.Terminals
          )
        ) {
          return;
        }

        //Efeective date empty for terminal
        if (!t.EffectiveDate && !checkStopOff) {
          errorMessages.push(
            LaneRateErrorMessages.DATE_MISSING.replace(
              '<Terminal>',
              terminalName
            )
          );
        }

        //No Rates are added. Exit cuurent iteration of loop.
        if (t.MileageZones.length === 0) {
          errorMessages.push(
            prefix +
            LaneRateErrorMessages.NO_RATES.replace('<Terminal>', terminalName)
          );
          return;
        }
        let lastValidValueIndex = 0;
        let firstNullValueIndex = t.MileageZones?.findIndex(
          (m: MileageZone) => {
            return checkStopOff
              ? m.BaseRateWithStopOff == null
              : m.BaseRate == null;
          }
        );
        for (let i = 0; i < t.MileageZones.length; i++) {
          let currentRate = checkStopOff
            ? t.MileageZones[i].BaseRateWithStopOff
            : t.MileageZones[i].BaseRate;

          if (i < t.MileageZones.length - 1) {
            let nextRate = checkStopOff
              ? t.MileageZones[i + 1].BaseRateWithStopOff
              : t.MileageZones[i + 1].BaseRate;

            //Rates should be in linearly increasing order
            if (currentRate && nextRate && currentRate >= nextRate) {
              warningMessages.push(
                prefix +
                LaneRateErrorMessages.LINEARLY_INCREASING_VALUE.replace(
                  '<Terminal>',
                  terminalName
                ).replace(
                  '<Range>',
                  this.getMileRangeString(t.MileageZones[i + 1])
                )
              );
            }
          }

          if (currentRate != null) {
            lastValidValueIndex = i;

            //Rate cannnot be zero
            if (currentRate === 0) {
              t.MileageZones[i].InValid = true;
              errorMessages.push(
                prefix +
                LaneRateErrorMessages.ZERO_VALUE.replace(
                  '<Terminal>',
                  terminalName
                ).replace(
                  '<Range>',
                  this.getMileRangeString(t.MileageZones[i])
                )
              );
            }

            //Rate should be greater than 0
            else if (currentRate < 0) {
              t.MileageZones[i].InValid = true;
              errorMessages.push(
                prefix +
                LaneRateErrorMessages.NEGATIVE_VALUE.replace(
                  '<Terminal>',
                  terminalName
                ).replace(
                  '<Range>',
                  this.getMileRangeString(t.MileageZones[i])
                )
              );
            }

            //Rate should be less than threshold of $10000
            else if (currentRate > 10000) {
              warningMessages.push(
                prefix +
                LaneRateErrorMessages.THRESHOLD_VALUE.replace(
                  '<Terminal>',
                  terminalName
                ).replace(
                  '<Range>',
                  this.getMileRangeString(t.MileageZones[i])
                )
              );
            }
          }
        }
        if (firstNullValueIndex !== -1) {
          //If Null value found in middle, treat that as error
          if (firstNullValueIndex < lastValidValueIndex) {
            errorMessages.push(
              prefix +
              LaneRateErrorMessages.BLANK_VALUES_IN_MIDDLE.replace(
                '<Terminal>',
                terminalName
              )
            );
            t.MileageZones.slice(0, lastValidValueIndex).forEach(
              (m: MileageZone) => {
                if (
                  checkStopOff
                    ? m.BaseRateWithStopOff == null
                    : m.BaseRate == null
                ) {
                  m.InValid = true;
                }
              }
            );
          }

          //If Null value found in end, treat that as warning
          else {
            warningMessages.push(
              prefix +
              LaneRateErrorMessages.BLANK_VALUES_IN_END.replace(
                '<Terminal>',
                terminalName
              )
            );
          }
        }
      });
    }

    dataValidations.ErrorMessages =
      dataValidations.ErrorMessages.concat(errorMessages);
    dataValidations.WarningMessages =
      dataValidations.WarningMessages.concat(warningMessages);
  }

  public validateSSPData(dataToValidate: any, dataValidations: Validations) {
    let errorMessages: string[] = [];
    let duplicateData: any[] = [];
    if (dataToValidate.Terminals?.length > 0) {
      dataToValidate.Terminals.forEach((t: SspTerminal) => {
        t.InValid = false;
        if (this.checkMandatoryFields(t, errorMessages)) {
          return;
        }
        if (t.OneWayMiles === 0 || t.OneWayMiles < 0) {
          t.InValid = true;
          errorMessages.push(
            LaneRateErrorMessages.INVALID_ONEWAY_MILE.replace(
              '<FromTerminal>',
              t.UI_From_Terminal_Alias || ''
            )
              .replace('<ToTerminal>', t.UI_To_Terminal_Alias || '')
              .replace('<Product>', t.UI_Product_Alias || '')
          );
        }
        if (t.RatePerLoad === 0 || t.RatePerLoad < 0) {
          t.InValid = true;
          errorMessages.push(
            LaneRateErrorMessages.INVALID_RATE_PERLOAD.replace(
              '<FromTerminal>',
              t.UI_From_Terminal_Alias || ''
            )
              .replace('<ToTerminal>', t.UI_To_Terminal_Alias || '')
              .replace('<Product>', t.UI_Product_Alias || '')
          );
        }

        this.validateSSPDataDuplicacy(
          duplicateData,
          dataToValidate?.Terminals,
          t,
          errorMessages
        );
      });
    }
    dataValidations.ErrorMessages =
      dataValidations.ErrorMessages.concat(errorMessages);
  }

  public checkMandatoryFields(
    terminal: SspTerminal,
    messages: string[]
  ): boolean {
    let missingFields = [];
    let matchingCriteria = [];

    if (!terminal.EffectiveDate) {
      missingFields.push('Effective date');
    }
    if (!terminal.TerminalNumberFrom) {
      missingFields.push('Supply terminal');
    } else {
      matchingCriteria.push(
        'Supply terminal: ' + terminal.UI_From_Terminal_Alias
      );
    }
    if (!terminal.TerminalNumberTo) {
      missingFields.push('Receiving terminal');
    } else {
      matchingCriteria.push(
        'Receiving terminal: ' + terminal.UI_To_Terminal_Alias
      );
    }
    if (!terminal.OneWayMiles) {
      missingFields.push('One way miles');
    }
    if (!terminal.RatePerLoad) {
      missingFields.push('Rate');
    }
    if (!terminal.UomId) {
      missingFields.push('UOM');
    }
    if (!terminal.ProductId) {
      missingFields.push('Product');
    } else {
      matchingCriteria.push('Product: ' + terminal.UI_Product_Alias);
    }

    if (missingFields.length) {
      terminal.InValid = true;
      let message = '';
      message = LaneRateErrorMessages.MANDATORY_FIELDS.replace(
        '<Fields>',
        missingFields.join(', ')
      );
      if (matchingCriteria.length) {
        message = message.concat(
          ` Row details - ${matchingCriteria.join(', ')}`
        );
      }
      messages.push(message);
      return true;
    }

    return false;
  }

  public validateSSPDataDuplicacy(
    duplicateData: SspTerminal[],
    allTerminals: SspTerminal[],
    terminal: SspTerminal,
    messages: string[]
  ) {
    let matchedRows = allTerminals.filter(
      (m) =>
        m.TerminalNumberFrom === terminal.TerminalNumberFrom &&
        m.TerminalNumberTo === terminal.TerminalNumberTo &&
        m.ProductId === terminal.ProductId &&
        m.OneWayMiles === terminal.OneWayMiles
    );

    //Duplicate range found anywhere in data
    if (
      matchedRows?.length > 1 &&
      !duplicateData.find(
        (m) =>
          m.TerminalNumberFrom === terminal.TerminalNumberFrom &&
          m.TerminalNumberTo === terminal.TerminalNumberTo &&
          m.ProductId === terminal.ProductId &&
          m.OneWayMiles === terminal.OneWayMiles
      )
    ) {
      duplicateData.push(terminal);
      terminal.InValid = true;
      messages.push(
        LaneRateErrorMessages.DUPLICATE_SSP.replace(
          '<FromTerminal>',
          terminal.UI_From_Terminal_Alias || ''
        )
          .replace('<ToTerminal>', terminal.UI_To_Terminal_Alias || '')
          .replace('<OneWayMiles>', terminal.OneWayMiles?.toString() || '')
          .replace('<Product>', terminal.UI_Product_Alias || '')
      );
    }
  }

  public validateACData(dataToValidate: AccessorialCharge, dataValidations: Validations) {
    let errorMessages: string[] = [];
    const duplicateData: AcBillingCode[] = [];
    if (dataToValidate.BillingCodes?.length > 0) {
      dataToValidate.BillingCodes.forEach((b: AcBillingCode) => {
        b.InValid = false;
        if (this.checkMandatoryFieldsAC(b, errorMessages)) {
          return;
        }
        this.validateACDataDuplicacy(
          duplicateData,
          dataToValidate?.BillingCodes,
          b,
          errorMessages
        );
      });
    }
    dataValidations.ErrorMessages =
      dataValidations.ErrorMessages.concat(errorMessages);
  }

  public checkMandatoryFieldsAC(billingData: AcBillingCode, messages: string[]) {
    const missingFields: string[] = [];
    const matchingCriteria: string[] = [];
    let message = '';
    if (!billingData.EffectiveDate) {
      missingFields.push('Effective date');
    }
    if (billingData.BillingCodeId == null || billingData.BillingCodeId === '') {
      missingFields.push('Billing Code CTL');
    } else {
      matchingCriteria.push('Billing Code CTL: ' + billingData.UI_BillingCTL);
    }
    if (!billingData.Description) {
      missingFields.push('Description');
    }
    if (billingData.Value == null || billingData.Value === '') {
      missingFields.push('Value');
    } else {
      matchingCriteria.push('Value: ' + billingData.Value);
    }
    if (billingData.UomId == null || billingData.UomId === '') {
      missingFields.push('UOM');
    } else {
      matchingCriteria.push('UOM: ' + billingData.UI_UOM);
    }

    if (missingFields.length) {
      billingData.InValid = true;
      message = LaneRateErrorMessages.MANDATORY_FIELDS.replace(
        '<Fields>',
        missingFields.join(', ')
      );
      if (matchingCriteria.length) {
        message = message.concat(
          ` Row details - ${matchingCriteria.join(', ')}`
        );
      }
      messages.push(message);
      return true;
    }
    return false;
  }

  public validateACDataDuplicacy(
    duplicateData: AcBillingCode[],
    allBillingData: AcBillingCode[],
    billingData: AcBillingCode,
    messages: string[]
  ) {
    const effDate = this.lcmsService.dateFormatter({
      value: billingData.EffectiveDate,
    });
    let matchedRows = allBillingData.filter(
      (x) =>
        effDate ===
          this.lcmsService.dateFormatter({ value: x.EffectiveDate }) &&
        billingData.BillingCodeId === x.BillingCodeId &&
        this.matchAccChargeValue(billingData, x) &&
        billingData.UomId === x.UomId
    );

    // Duplicate range found anywhere in data
    if (
      matchedRows?.length > 1 &&
      !duplicateData.find(
        (x) =>
          effDate ===
            this.lcmsService.dateFormatter({ value: x.EffectiveDate }) &&
          billingData.BillingCodeId === x.BillingCodeId &&
          this.matchAccChargeValue(billingData, x) &&
          billingData.UomId === x.UomId
      )
    ) {
      duplicateData.push(billingData);
      billingData.InValid = true;
      messages.push(
        `Duplicate records found: Effective Date - ${effDate}, Billing Code CTL - ${billingData.UI_BillingCTL}, Value - ${billingData.Value} and UOM - ${billingData.UI_UOM}`
      );
    }
  }

  private matchAccChargeValue(billingData1: AcBillingCode, billingData2: AcBillingCode) {
    if (!isNaN(billingData1.Value as any) && !isNaN(billingData2.Value as any)) {
      return +billingData1.Value === +billingData2.Value;
    }
    return billingData1.Value === billingData2.Value;
  }

  /**
   * This method return a list of mile ranges to be shown in grid.
   * If terminals are not available (carrier is newly created and has no terminal rates),
   * create the mile range from MaxMileRange of carrier.
   * Otherwise, create mile range from data available in each terminal of carrier.
   *
   * @param terminals is an array of Terminals with BaseRate and MileRange
   * @returns an array of mile ranges in text format e.g. ['0-5', '6-10', '11-15', '16-20', ...]
   */
  public getMileRanges(
    terminals: ClrTerminal[] | undefined
  ): { MileRange: IMileRange }[] {
    if (terminals?.length) {
      return this.getMileRangeFromTerminals(terminals);
    } else {
      return this.getDefaultMileRanges();
    }
  }

  /**
   * This method finds the terminal with highest number of MileageZones (indicating super set of mile ranges for all terminals).
   * The MileRange array of that terminal is returned in sorted order (from lowest StartRange to highest).
   *
   * @param terminals is an array of Terminals with BaseRate and MileRange
   * @returns an array of mile ranges in { MileRange: IMileRange | undefined }[] format e.g. [{ MileRange: { StartRange: 0, EndRange: 5 } }, { MileRange: { StartRange: 6, EndRange: 10 } }, ...]
   */
  private getMileRangeFromTerminals(
    terminals: ClrTerminal[]
  ): { MileRange: IMileRange }[] {
    const terminalWithMostMileageZones = terminals.reduce(
      (maxTerminal: ClrTerminal, currentTerminal: ClrTerminal) => {
        return currentTerminal.MileageZones.length >
          maxTerminal.MileageZones.length
          ? currentTerminal
          : maxTerminal;
      },
      terminals[0]
    );
    return terminalWithMostMileageZones.MileageZones.sort(
      (a, b) => a.StartRange - b.StartRange
    ).map((x) => {
      return {
        MileRange: { StartRange: x.StartRange, EndRange: x.EndRange },
      };
    });
  }

  /**
   * This method returns mile ranges in the interval of 5 miles starting from 0 till MaxMileRange of the carrier.
   *
   * @returns an array of mile ranges in { MileRange: string | undefined }[] format e.g. [{ MileRange: '0-5' }, { MileRange: '6-10' }, { MileRange: '11-15' }, ...]
   */
  private getDefaultMileRanges(): { MileRange: IMileRange }[] {
    const maxMileRange: number = this.selectedCarrier.MaxMileRange;
    const mileRanges: { MileRange: IMileRange }[] = [];

    for (let i = 1; i <= maxMileRange; i += 5) {
      const start = i === 1 ? 0 : i;
      const end = Math.min(i + 4, maxMileRange);
      mileRanges.push({ MileRange: { StartRange: start, EndRange: end } });
    }

    return mileRanges;
  }

  public isVersionPublished(): any {
    return this.selectedVersion?.PublishStatus === PublishStatusEnum.Published;
  }

  public isPublishInProgress(): any {
    return this.selectedVersion?.PublishStatus === PublishStatusEnum.InProgress;
  }

  public isVersionLive(): any {
    return this.selectedVersion?.VersionType === VersionTypeEnum.Live;
  }

  public isVersionCurrentlyPublished(): any {
    let publishedVersions = this.laneRateMetadata.filter(
      (v: VersionMetadata) => v.VersionType === VersionTypeEnum.Published
    );

    let lastPublishedVersion!: VersionMetadata;
    if (publishedVersions?.length > 0) {
      publishedVersions = publishedVersions.sort((a, b) => {
        if (a.LastPublishedOn > b.LastPublishedOn) {
          return -1;
        }
        if (a.LastPublishedOn < b.LastPublishedOn) {
          return 1;
        }
        return 0;
      });
      lastPublishedVersion = publishedVersions[0];
    }
    if (this.selectedVersion?.Id === lastPublishedVersion?.Id) {
      return true;
    } else {
      return false;
    }
  }

  public isUploadAllowed(): any {
    let liveVersion = this.laneRateMetadata?.find(
      (v: VersionMetadata) => v.VersionType === VersionTypeEnum.Live
    );

    return (
      (this.selectedVersion?.VersionType === VersionTypeEnum.Live &&
        this.selectedVersion?.PublishStatus !== PublishStatusEnum.InProgress) ||
      this.selectedVersion?.VersionType === VersionTypeEnum.Draft
    );
  }

  public handleAPIErrorResponse(
    error: any,
    versionNameFormControl: FormControl | null,
    versionErrorField: any,
    apiName: string
  ) {
    if (
      versionNameFormControl &&
      versionErrorField &&
      error?.error?.result?.Error &&
      error?.error?.result?.Error[0]?.ResponseCode === 300
    ) {
      versionNameFormControl?.setErrors({ incorrect: true });
      versionErrorField.error = MessagesEnum.VERSION_DUPLICATE;
    } else if (
      error?.error?.result?.Error &&
      error?.error?.result?.Error[0]?.ResponseCode === 409 &&
      error?.error?.result?.Error[0]?.Message
    ) {
      this.snackBarService.error({
        message: error?.error?.result?.Error[0]?.Message,
      });
    } else {
      let message = MessagesEnum.SOMETHING_WENT_WRONG + ' :: ' + apiName;
      if (error?.error?.result && typeof error.error.result === 'string') {
        message = error.error.result;
      }
      this.snackBarService.error({
        message,
      });
      console.log(error);
    }
  }

  populateColDefs() {
    const colDefs: ColDef[] = [
      {
        headerName: '',
        field: 'action',
        width: 55,
        filter: false,
        floatingFilter: false,
        suppressMenu: true,
        suppressColumnsToolPanel: true,
        suppressSizeToFit: true,
        unSortIcon: false,
        pinned: 'left',
        lockPosition: true,
        headerCheckboxSelection: this.lcmsService.userHasReadWriteAccess(
          LCMSAccessTypes.RATEMASTER_RW
        ),
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: (params: any) =>
          this.lcmsService.userHasReadWriteAccess(
            LCMSAccessTypes.RATEMASTER_RW
          ) &&
          (params.data.IsModified ||
            params.data.SapPublishStatus === PublishStatusEnum.Failed),
      },
      {
        headerName: 'SCAC',
        field: 'UI_SCAC',
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
        width: 120,
        sort: 'asc',
        sortIndex: 1,
        pinned: 'left',
        cellClassRules: {
          modifiedGridRow: (params: any) => {
            return params.data?.IsModified;
          },
          failedGridRow: (params: any) => {
            return params.data?.UI_IsFailed
          },
          duplicateGridRow: (params: any) => {
            return params.data?.UI_IsDuplicate
          }
        },
      },
      {
        headerName: 'source screen',
        field: 'SourceScreenType',
        hide: true,
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
        width: 175,
        sort: 'asc',
        sortIndex: 2,
      },
      {
        headerName: 'division',
        field: 'UI_Division',
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
        valueGetter: (params: any) => {
          return params?.data?.UI_Division ? params.data?.UI_Division : 'NA';
        },
        width: 122,
      },
      {
        headerName: 'product',
        field: 'UI_Product',
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
        width: 145,
        sort: 'asc',
        sortIndex: 4,
      },
      {
        headerName: 'terminal',
        field: 'UI_Terminal',
        sort: 'asc',
        sortIndex: 3,
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
      },
      {
        headerName: 'terminal number',
        field: 'TerminalNumber',
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
        width: 180,
      },
      {
        headerName: 'effective date',
        field: 'EffectiveDate',
        type: AgGridColumnTypeEnum.DATE_COLUMN,
        width: 180,
        sort: 'asc',
        sortIndex: 6,
      },
      {
        headerName: 'ceiling mile',
        field: 'EndRange',
        type: AgGridColumnTypeEnum.NUMBER_COLUMN,
        width: 160,
        sort: 'asc',
        sortIndex: 5,
      },
      {
        headerName: 'range mile',
        field: 'UI_Range_Mile',
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
        width: 140,
      },
      {
        headerName: 'rate',
        field: 'Rate',
        type: AgGridColumnTypeEnum.DOLLAR_CURRENCY_COLUMN,
        width: 115,
      },
      {
        headerName: 'SAP plant',
        field: 'UI_SAP_Plant',
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
        width: 145,
      },
      {
        headerName: 'CLR last published by',
        field: 'LastPublishedBy',
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
      },
      {
        headerName: 'CLR last published on',
        field: 'LastPublishedOn',
        type: AgGridColumnTypeEnum.DATE_TIME_COLUMN,
      },
      {
        headerName: 'SAP publish status',
        field: 'SapPublishStatus',
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
      },
      {
        headerName: 'SAP last published by',
        field: 'SapLastPublishedBy',
        type: AgGridColumnTypeEnum.TEXT_COLUMN,
      },
      {
        headerName: 'SAP last published on',
        field: 'SapLastPublishedOn',
        type: AgGridColumnTypeEnum.DATE_TIME_COLUMN,
      },
    ];
    return colDefs;
  }

  public isColEditable(_params: any) {
    return (
      this.lcmsService.userHasReadWriteAccess(LCMSAccessTypes.CLR_RW) &&
      !this.isVersionPublished() &&
      !this.isPublishInProgress()
    );
  }

  public validateFscData(dataToValidate: FscRates,dataValidations: Validations) {
    let errorMessages: string[] = [];
    if (dataToValidate.FscRegionIndexRates?.length > 0) {
      dataToValidate.FscRegionIndexRates.forEach((t: FscRegionIndexRate) => {
        t.InValid = false;
        if (this.checkMandatoryFieldsFsc(t, errorMessages)) {
          return;
        }
        if (t.IndexFuelPrice === 0 || t.IndexFuelPrice < 0) {
          t.InValid = true;
          errorMessages.push(
            FscErrorMessages.INVALID_RATE)
        }
        if (t.FscPerMile === 0 || t.FscPerMile < 0) {
          t.InValid = true;
          errorMessages.push(
            FscErrorMessages.INVALID_RATE_FSC + `for region ${ t.SeriesName}`)
        }

      });
    }
    dataValidations.ErrorMessages =
      dataValidations.ErrorMessages.concat(errorMessages);
  }

  public checkMandatoryFieldsFsc(
    fscIndexRates: FscRegionIndexRate,
    messages: string[]
  ): boolean {
    let missingFields = [];
    if (fscIndexRates.IndexFuelPrice == null) {
      missingFields.push(`Index Fuel Price is missing for region  ${fscIndexRates.SeriesName}`);
    }
    if (fscIndexRates.FscPerMile == null) {
      missingFields.push(`Fsc Per Mile is missing for region  ${fscIndexRates.SeriesName}`);
    }

    if (missingFields.length) {
      fscIndexRates.InValid = true;
      let message = '';
      message = FscErrorMessages.MANDATORY_FIELDS.replace(
        '<Fields>',
        missingFields.join(', ')
      );
     
      messages.push(message);
      return true;
    }

    return false;
  }

  public validationCheckFsc(fscRateData: FscRates) {
    let allValidations: Validations[] = [];

    let errorValidationsForFSC: Validations = new Validations({
      ValidationType: ValidationType.Error,
      ScreenType: ScreenType.FuelSurcharge,
    });
    if (
      fscRateData?.FscRegionIndexRates &&
      fscRateData?.IsUIModified
    ) {
      this.validateFscData(
        fscRateData,
        errorValidationsForFSC
      );
    }
    if (
      errorValidationsForFSC.ErrorMessages.length > 0 ||
      errorValidationsForFSC.WarningMessages.length > 0
    ) {
      allValidations.push(errorValidationsForFSC);
    }

    return allValidations;
  }
// Fsc Share CC users emails //
  public getFscUserEmailIds() {
    const ccEmails= this.clrReferenceData.getReferenceDataByKeyAndName(
          ReferenceDataKeyEnum.GENERAL,
          GeneralRefDataKeyEnum.FSC_USER_EMAILS
        )?.Value;
        return ccEmails;
  }
  // Fsc Share TO  carriers emails //
  public getFscCarriersEmailIds() {
    let carriers = this.clrReferenceData.getReferenceDataByKey(
      ReferenceDataKeyEnum.CARRIERS
    )
    if (carriers && carriers.length > 0) {
      const toEmails = carriers
        .flatMap((a: any) => a.Email ? a.Email.split(',') : []) // Split comma-separated emails and flatten the array
        .map((email: string) => email.trim()) // Trim any extra spaces
        .filter((email: string) => email) // Filter out empty strings
        .join('; ');
      return toEmails;
    }
    return "";
  }

}

